import {useAlterBox} from "../../../Modals/AlertModal/Context";
import {useEffect, useState} from "react";
import TableContainer from "../../../components/Table/TableContainer";
import Table from "../../../components/Table/Table";
import TableHeader from "../../../components/Table/TableHeader";
import TableCell from "../../../components/Table/TableCell";
import TableBody from "../../../components/Table/TableBody";
import TableLoadingRow from "../../../components/Table/TableLoadingRow";
import TableEmpty from "../../../components/Table/TableEmpty";
import TableRow from "../../../components/Table/TableRow";
import Avatar from "../../../components/Image/Avatar/Avatar";
import TableFooter from "../../../components/Table/TableFooter";
import Paginator from "../../../components/Table/paginator-main";
import {getColorForCallType, getUri} from "../../../utils/utils";
import {useTheme} from "../../../redux/Reducers/ThemeReducer";
import useForm from "../../../Hooks/useForm";
import URLS from "../../../API/Axios/URLS";
import {toast} from "react-toastify";


export default function CallRequestTable({className, ...props}) {

    const {alterBoxConfig, actions} = useAlterBox({
        title: "Delete Property",
        message: "Are you sure you want to delete this property?",
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        onConfirm: onConfirm,
        onCancel: onCancle,

    });
    const [form, error, loading, setFormData, formAction] = useForm([])
    useEffect(() => {
        formAction.get(URLS.appointmentList).then((data) => {
            setFormData(data?.data)

        }).catch((error) => {
            toast.error("Error in fetching call request")
        })
    }, []);
    const [isLoading, setIsLoading] = useState(false);
    const {isDarkMode} = useTheme();

    function onConfirm() {

    }

    function onCancle() {

    }

    let pageStart = (form?.currentPage - 1) * form?.pageSize;
    let pageEnd = form?.currentPage * form?.pageSize;
    return (
        <div className={`w-full h-full overflow-auto ${className}`}>

            <TableContainer>
                <Table>
                    <TableHeader>
                        <tr>
                            <TableCell>Name</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Call Type </TableCell>
                            <TableCell>Event</TableCell>
                            <TableCell>Actions</TableCell>
                        </tr>
                    </TableHeader>
                    <TableBody>
                        {isLoading && <TableLoadingRow/>}
                        {form?.results?.length === 0 && <TableEmpty/>}

                        {!isLoading && form?.results?.map((row, i) => {
                            // if day is today then show today
                            if (row?.date === new Date().toISOString().split("T")[0]) {
                                return (<TableRow key={i}>
                                    <TableCell>
                                        <div className="flex items-center text-sm">
                                            <Avatar className="hidden mr-3 md:block"
                                                    src={getUri(row?.patient?.user?.dp)}
                                                    alt="User image"/>
                                            <div>
                                                <p className="font-semibold">{row?.patient?.user?.full_name || row?.name}</p>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <span className="text-sm">{row?.date}</span>
                                    </TableCell>
                                    <TableCell>
                                        <span className="text-sm">{row?.startTime}-{row?.endTime}</span>
                                    </TableCell>
                                    <TableCell>
                                        <div className={"flex-row flex"}>
                                            <p
                                                style={{
                                                    backgroundColor: getColorForCallType(row?.media, isDarkMode) + "1a",
                                                    color: getColorForCallType(row?.media, isDarkMode)
                                                }}
                                                className={"text-xs px-2 rounded-full text-gray-500 dark:text-gray-400"}>{row?.media}</p>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className={"flex-row flex"}>
                                            <p
                                                style={{
                                                    backgroundColor: getColorForCallType(row?.call_type, isDarkMode) + "1a",
                                                    color: getColorForCallType(row?.call_type, isDarkMode)
                                                }}
                                                className={"text-xs px-2 rounded-full text-gray-500 dark:text-gray-400"}>{row?.call_type}</p>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        {
                                            row?.status !== "COMPLETED" && row?.status !== "CANCELLED" && row?.status !== "MISSED" ?
                                                <div className={"grid grid-cols-2 gap-2"}>
                                                    <button
                                                        onClick={() => {

                                                            formAction.post(URLS.joinCall, () => {
                                                                return {id: row?.id}
                                                            }).then((data) => {
                                                                //    redirect to call page
                                                                //     console.log(data.data.data)
                                                                window.location.href = "/app/calls/" + data?.data?.data?.id
                                                            }).catch((error) => {

                                                                toast(error?.response?.data?.message
                                                                    || "Error in joining call"
                                                                    , {type: "error"})
                                                                
                                                            })
                                                        }}
                                                        className={`${
                                                            row?.call?.doctor_token ? "bg-green-500 text-white" : "primary-button-color"
                                                        } rounded-md py-1`}>
                                                        {
                                                            row?.call?.doctor_token ? "Join Call" : "Confirm"
                                                        }
                                                    </button>
                                                    {
                                                        !row?.call?.doctor_token &&
                                                        <button
                                                            className={"primary-button-outline-color rounded-md py-1"}>
                                                            Cancel
                                                        </button>}
                                                </div> : <div className={"grid grid-cols-2 gap-2"}>
                                                    N/A
                                                </div>

                                        }
                                    </TableCell>
                                </TableRow>)
                            }

                        })}
                    </TableBody>
                </Table>
                <TableFooter>
                    <p className={'flex-1 flex dark:text-gray-400 ml-4 text-xs items-center font-semibold tracking-wide uppercase'}>
                        Showing {pageStart + 1} to {pageEnd} of {form?.totalResult} entries
                    </p>
                    <Paginator
                        currentPage={form?.currentPage}
                        totalPages={form?.totalPage}
                        onNext={(e) => {
                            setFormData({...form, currentPage: e})
                        }}
                        onPrev={(e) => setFormData({...form, currentPage: e})}
                        onPageNumberClick={(e) => setFormData({...form, currentPage: e})}
                    />

                </TableFooter>

            </TableContainer>
        </div>

    )
}
