import CallRequestTable from "./call-request-table";

export default function CallRequest({className, ...props}) {

    return (
        <div className={` ${className} py-4`}>
            <div className={"flex flex-col gap-5"}>
                <p className={"font-semibold text-xl"}>
                    Call Request
                </p>

                <CallRequestTable />
            </div>
        </div>

    )
}