import {useAlterBox} from "../../../../Modals/AlertModal/Context";
import {useRef, useState} from "react";
import DummyData from "../../../dashboard/tables/ClientTable/varaibales/DummyData";
import PageTitle from "../../../../components/utils/PageTitle";
import {Link, useNavigate} from "react-router-dom";
import TableRow from "../../../../components/Table/TableRow";
import {AiFillEye, AiOutlineAppstoreAdd} from "react-icons/ai";
import TableContainer from "../../../../components/Table/TableContainer";
import Table from "../../../../components/Table/Table";
import TableHeader from "../../../../components/Table/TableHeader";
import TableCell from "../../../../components/Table/TableCell";
import TableBody from "../../../../components/Table/TableBody";
import TableLoadingRow from "../../../../components/Table/TableLoadingRow";
import TableEmpty from "../../../../components/Table/TableEmpty";
import Avatar from "../../../../components/Image/Avatar/Avatar";
import Badge from "../../../../components/Badges/Badge";
import {MdDelete, MdEditNote} from "react-icons/md";
import TableFooter from "../../../../components/Table/TableFooter";
import Paginator from "../../../../components/Table/Paginator";
import dummyPatientList from "../../../../utils/dummy-data/dummy-patient-list";
import download, {getUri} from "../../../../utils/utils";
import {FaFileDownload} from "react-icons/fa";
import useListApi from "../../../../Hooks/useListApi";
import URLS from "../../../../API/Axios/URLS";
import moment from "moment";
import SearchInput from "../../../../components/fields/search-input/search-input";
import {BiCalendarCheck} from "react-icons/bi";
import DoctorReportModal from "../../../../Modals/doctor-report-modal/doctor-report-modal";
import {BsCloudDownload} from "react-icons/bs";
import {GrDocument} from "react-icons/gr";


export default function DoctorTable({className, isModal, onRowSelect, ...props}) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    let [data, filter, isLoading, patientActions] = useListApi(URLS.DoctorsList, {
        search: "",
    });
    const navigate = useNavigate();
    const doctor = useRef(-1);


    return (
        <div className={`w-full h-full overflow-auto ${className}`}>
            <DoctorReportModal
                doctorId={doctor.current}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            />


            {!isModal && <div className={"w-full gap-3 flex mb-10 justify-end items-center"}>
                <Link title={"Add Doctor"}
                      className={"w-10 h-10 rounded-full justify-center items-center flex  text-white bg-brand-800"}
                      to={"/app/doctor/add"}>
                    <AiOutlineAppstoreAdd className={"text-base"}/>
                </Link>


            </div>
            }
            {
                <div
                    className={"w-full md:w-1/2 lg:w-1/3 ml-auto flex  rounded-full gap-4 items-center mb-4"}
                >
                    <SearchInput
                        hideRightIcon={true}
                        onChange={(e) => {
                            patientActions.updateFilter({
                                search: e.target.value
                            })
                        }}
                        outerClassName={"border-[1px]  rounded-full"}
                        placeholder={"Search Doctor"}
                    />


                </div>
            }

            <TableContainer>
                <Table>
                    <TableHeader>
                        <tr>
                            <TableCell>Name</TableCell>
                            <TableCell>Register Date</TableCell>
                            <TableCell>Specialty </TableCell>
                            {!isModal && <TableCell>Report</TableCell>}
                            <TableCell>Contact </TableCell>
                            {isModal && <TableCell>View Profile</TableCell>}
                            {!isModal && <TableCell>Document</TableCell>}
                            {!isModal && <TableCell>Actions</TableCell>}
                        </tr>
                    </TableHeader>
                    <TableBody>
                        {isLoading && <TableLoadingRow/>}
                        {data?.results?.length === 0 && <TableEmpty/>}

                        {!isLoading && data?.results.map((row, i) => {
                            let user = row?.user;
                            return <TableRow
                                onClick={() => {
                                    if (onRowSelect)
                                        onRowSelect(row)
                                }}
                                className={"cursor-pointer"}
                                key={i}>
                                <TableCell>
                                    <div className="flex items-center text-sm">
                                        <Avatar className="hidden mr-3 md:block" src={getUri(user?.dp?.file)}
                                                alt="User image"/>
                                        <div>
                                            <p className="font-semibold">{user?.full_name}</p>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <span className="text-sm">{
                                        moment(new Date(user?.date_joined)).format('ll')
                                    }</span>
                                </TableCell>


                                <TableCell>
                                    <span className="text-sm">{

                                        row?.speciality
                                    }</span>
                                </TableCell>
                                {!isModal && <TableCell>
                                    <div className={"flex flex-row"}>
                                        <div
                                            title={"download report"}
                                            onClick={() => {
                                                doctor.current = row.id;
                                                setIsModalOpen(true)
                                            }}
                                            className={" cursor-pointer px-4 hover:opacity-90 rounded-full justify-center items-center flex  text-white bg-brand-800"}
                                        >
                                            Download Report
                                        </div>
                                    </div>
                                </TableCell>}
                                <TableCell>
                                    <span className="text-sm">{

                                        row?.contact
                                    }</span>
                                </TableCell>
                                {isModal && <TableCell>
                                    <Link to={"/app/doctor/" + row.id}>
                                        <AiFillEye title={"view"}

                                                   className={"cursor-pointer"}/>
                                    </Link>

                                </TableCell>}
                                {!isModal && <TableCell>

                                    <Link to={getUri(row?.documents?.file)}
                                          target={"_blank"}
                                    >
                                        <GrDocument title={"view document"}

                                                    className={"cursor-pointer"}/>
                                    </Link>

                                </TableCell>}
                                {
                                    !isModal && <TableCell>
                                        <div className={"flex gap-2"}>
                                            <div onClick={() => {
                                                navigate("/app/schedule-admin/" + row.id, {state: {doctor: row}})

                                            }}>
                                                <BiCalendarCheck title={"View Schedule"}

                                                                 className={"cursor-pointer"}/>
                                            </div>
                                            <Link to={"/app/doctor/update/" + row.id}>
                                                <MdEditNote title={"edit"}

                                                            className={"cursor-pointer"}/>
                                            </Link>
                                            <Link to={"/app/doctor/" + row.id}>
                                                <AiFillEye title={"view"}

                                                           className={"cursor-pointer"}/>
                                            </Link>




                                        </div>
                                    </TableCell>
                                }
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
                <TableFooter>
                    <p className={'flex-1 flex dark:text-gray-400 ml-4 text-xs items-center font-semibold tracking-wide uppercase'}>
                        {data?.count} entries
                    </p>
                    <Paginator
                        currentPage={data?.currentPage}
                        totalPages={data?.totalPages}
                        onNext={(e) => {
                            patientActions.loadNextPage()
                        }}
                        onPrev={(e) => patientActions.loadPrevPage()}
                        onPageNumberClick={(e) => {
                            patientActions.loadSpecificPage(e)
                        }}
                    />


                </TableFooter>

            </TableContainer>
        </div>

    )
}
