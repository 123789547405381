import PageTitle from "../../../components/utils/PageTitle";
import FormCard from "../../../components/utils/FormCard";
import FormItem from "../../../components/Form/FormItem/FormItem";
import FormButton from "../../../components/Form/Button/FormButton";
import useForm from "../../../Hooks/useForm";
import URLS from "../../../API/Axios/URLS";
import moment from "moment";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../../API/Axios/AxiosConfig";
import {useEffect, useState} from "react";
import Spinner from "../../../components/Loaders/Spinner/Spinner";
import DpCircle from "../../../components/avatar/dp/DpCircle";
import {getUri} from "../../../utils/utils";
import PageLoading from "../../../components/Loaders/page-loading/page-loading";

export default function AddUpdatePatient({className, ...props}) {
    let {id} = useParams();
    const [form, error, loading, setForm, actions] = useForm({
        user: {
            full_name: "",
            email: "",
            date_of_birth: new Date().toISOString().split("T")[0],
            cnic: "",
            martial_status: "SINGLE",
            gender: "MALE",
            address: "",
        },
        have_chronic_disease: false,
        ify_disease: "",
        contact: "",
        emergency_contact: "",
    });
    const navigation = useNavigate();
    const [loadingData, setLoadingData] = useState(false);

    function getFormData() {
        setLoadingData(true);
        axiosInstance.get(URLS.PatientCrud, {
            params: {
                id: id
            }
        }).then(res => {
            let data = res.data?.data;
            setForm(
                {
                    ...data,
                    user: {
                        ...data.user,
                        date_of_birth: moment(data.user.date_of_birth).format("YYYY-MM-DD")
                    }
                }
            )
            setLoadingData(false);
        }).catch(err => {
            toast(err?.response?.data?.message || err?.response?.data?.detail || "Something went wrong")
            navigation("/app/patient")
        })

    }

    useEffect(() => {
        if (id) {
            getFormData()
        }
    }, []);

    function handleSubmit(e) {
        e.preventDefault();
        if (id) {
            actions.put(URLS.PatientCrud, (data) => {
                return {
                    ...data,
                    user: {
                        ...data.user,
                        dp: data.user.dp?.id
                    },
                    date_of_birth: moment(data.date_of_birth).format("YYYY-MM-DD")
                }
            }).then(res => {
                toast.success("Patient Updated Successfully")
                navigation("/app/patient")
            }).catch(err => {
                console.log(err);
            })
            return;
        }
        actions.post(URLS.PatientCrud, (data) => {
            return {
                ...data,
                user: {
                    ...data.user,
                    dp: data.user.dp?.id
                },
                date_of_birth: moment(data.date_of_birth).format("YYYY-MM-DD")
            }
        }).then(res => {
            toast.success("Patient Added Successfully")
            navigation("/app/patient")
        }).catch(err => {
            console.log(err);
        })

    }

    if (loadingData)
        return <PageLoading/>

    return (
        <form onSubmit={handleSubmit}
              className={`w-full flex flex-col h-full overflow-auto  pb-20 mt-2 xl:px-10 ${className}`}>
            <PageTitle>
                {
                    id ? "Update " : "Add"
                } Patient
            </PageTitle>


            <FormCard className={"mt-8 px-4 lg:px-8  pb-8"}>
                <PageTitle>
                    Information
                </PageTitle>

                <div className={"grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4"}>
                    <div className={"flex justify-center items-center lg:col-span-2"}>
                        <DpCircle
                            src={getUri(form?.user?.dp?.file)}
                            onChange={(dp) => {
                                setForm({
                                    ...form, user: {
                                        ...form.user,
                                        dp: dp
                                    }
                                })
                            }}
                        />
                    </div>
                    <FormItem
                        label={"Full Name"}
                        type={"error"}

                        variant={"auth"}
                        message={error?.user?.full_name}
                    >
                        <input
                            value={form?.user?.full_name}
                            placeholder={"John"}
                            required={true}
                            type={"text"}
                            onChange={(e) => {
                                setForm({
                                    ...form, user: {
                                        ...form.user,
                                        full_name: e.target.value

                                    }
                                })
                            }}
                        />
                    </FormItem>
                    <FormItem
                        label={"Email"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.user?.email}
                    >
                        <input
                            value={form?.user?.email}
                            placeholder={"example@gmail.com"}
                            min={0}
                            required={true}
                            type={"email"}
                            onChange={(e) => {
                                setForm({
                                    ...form, user: {
                                        ...form.user,
                                        email: e.target.value
                                    }
                                })
                            }}
                        />
                    </FormItem>

                    <FormItem
                        label={"Gender"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.user?.gender}
                    >
                        <select
                            value={form?.user?.gender}
                            required={true}
                            className={"dark:bg-darkTheme-800 dark:text-white cursor-pointer"}
                            onChange={(e) => {
                                setForm({
                                    ...form, user: {
                                        ...form.user,
                                        gender: e.target.value
                                    }
                                })
                            }}
                        >
                            <option className={"text-base"} value={"MALE"}>Male</option>
                            <option className={"text-base"} value={"FEMALE"}>Female</option>
                            <option className={"text-base"} value={"OTHER"}>Other</option>
                        </select>
                    </FormItem>
                    <FormItem
                        label={"Date of Birth"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.user?.date_of_birth}
                    >
                        <input
                            value={form?.user?.date_of_birth}
                            required={true}
                            type={"date"}
                            onChange={(e) => {
                                setForm({
                                    ...form, user: {
                                        ...form.user,
                                        date_of_birth: e.target.value
                                    }
                                })
                            }}
                        />
                    </FormItem>


                    <FormItem
                        label={"NIN#"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.user?.cnic}
                    >
                        <input
                            value={form?.user?.cnic}
                            placeholder={"nin"}
                            required={false}
                            type={"text"}

                            pattern={'^\\d{11}$'}
                            title={"NIN should be 11 digits"}
                            onChange={(e) => {
                                setForm({
                                    ...form, user: {
                                        ...form.user,
                                        cnic: e.target.value
                                    }
                                })
                            }}
                        />
                    </FormItem>
                    <FormItem
                        label={"Marital Status"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.user?.martial_status}
                    >
                        <select
                            value={form?.user?.martial_status}
                            required={true}
                            className={"dark:bg-darkTheme-800 dark:text-white cursor-pointer"}
                            onChange={(e) => {
                                setForm({
                                    ...form, user: {
                                        ...form.user,
                                        martial_status: e.target.value
                                    }
                                })
                            }}
                        >
                            <option className={"text-base"} value={"SINGLE"}>Single</option>
                            <option className={"text-base"} value={"MARRIED"}>Married</option>
                            <option className={"text-base"} value={"OTHER"}>Other</option>
                        </select>
                    </FormItem>
                    <FormItem
                        label={"Chronic Disease"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.have_chronic_disease}
                    >
                        <select
                            value={form?.have_chronic_disease ? "Yes" : "No"}
                            required={true}
                            className={"dark:bg-darkTheme-800 dark:text-white cursor-pointer"}
                            onChange={(e) => {
                                setForm({...form, have_chronic_disease: e.target.value === "Yes"})
                            }}
                        >
                            <option className={"text-base"} value={"Yes"}>Yes</option>
                            <option className={"text-base"} value={"No"}>No</option>
                        </select>
                    </FormItem>
                    <FormItem
                        label={"IFY Disease"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.ify_disease}
                    >
                        <input
                            value={form?.ify_disease}
                            placeholder={"if any"}
                            type={"text"}
                            onChange={(e) => {
                                setForm({...form, ify_disease: e.target.value})
                            }}
                        />
                    </FormItem>
                    <FormItem
                        label={"Address"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.user?.address}
                    >
                        <input
                            value={form?.user?.address}
                            placeholder={"address"}
                            type={"text"}
                            onChange={(e) => {
                                setForm({...form, user: {...form.user, address: e.target.value}})
                            }}
                        />
                    </FormItem>
                    <FormItem
                        label={"Contact"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.contact}
                    >
                        <input
                            value={form?.contact}
                            placeholder={"contact"}
                            required={true}
                            type={"text"}
                            pattern={"^\+234\d{10}$"}
                            title={"Contact should be in +234xxxxxxxxxx format"}
                            onChange={(e) => {
                                setForm({...form, contact: e.target.value})
                            }}
                        />
                    </FormItem>
                    <FormItem
                        label={"Emergency Contact"}
                        type={"error"}
                        className={""}
                        variant={"auth"}
                        message={error?.emergency_contact}
                    >
                        <input
                            value={form?.emergency_contact}
                            placeholder={"Emergency contact"}
                            required={true}
                            type={"text"}
                            pattern={"^\+234\d{10}$"}
                            title={"Contact should be in +234xxxxxxxxxx format"}
                            onChange={(e) => {
                                setForm({...form, emergency_contact: e.target.value})
                            }}
                        />
                    </FormItem>
                    <FormItem
                        label={"Password"}
                        type={"error"}
                        className={""}
                        variant={"auth"}
                        message={error?.user?.password}
                    >
                        <input
                            value={form?.user?.password}
                            placeholder={"password"}

                            type={"password"}
                            onChange={(e) => {
                                setForm({
                                    ...form, user: {
                                        ...form.user,
                                        password: e.target.value
                                    }
                                })
                            }}
                        />
                    </FormItem>
                </div>

                <FormButton
                    loading={loading}
                    type={"submit"} className={" mt-8 w-[500px] m-auto self-center "}>
                    Submit
                </FormButton>
            </FormCard>


        </form>

    )
}
