const AppointmentTypes = [
    {
        label: 'Upcoming Appointments',
        value: 'upcoming'
    }, {
        label: 'Past Appointments',
        value: 'COMPLETED'
    }
]

export default AppointmentTypes;