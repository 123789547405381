import FormItem from "../../../components/Form/FormItem/FormItem";
import FormButton from "../../../components/Form/Button/FormButton";
import useForm from "../../../Hooks/useForm";
import {Link, useNavigate} from "react-router-dom";
import {useState} from "react";
import URLS from "../../../API/Axios/URLS";
import moment from "moment/moment";
import {toast} from "react-toastify";

export default function PatientRegister({className, ...props}) {
    const navigation = useNavigate();
    const [form, error, loading, setForm, actions] = useForm({
        user: {
            full_name: "",
            email: "",
            date_of_birth: new Date().toISOString().split("T")[0],
            cnic: "",
            martial_status: "SINGLE",
            gender: "MALE",
            address: "",
        },
        have_chronic_disease: false,
        ify_disease: "",
        contact: "",
        emergency_contact: "",
    });


    function handleSubmit(e) {
        e.preventDefault();
        actions.post(URLS.PatientRegister, (data) => {
            return {
                ...data,
                user: {
                    ...data.user,
                    dp: data.user.dp?.id
                },
                date_of_birth: moment(data.date_of_birth).format("YYYY-MM-DD")
            }
        }).then(res => {
            toast.success("Account Created Successfully", {
                toastId: "patient-create"
            })
            navigation("/auth/login")
        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <form onSubmit={handleSubmit}
              className="mt-[4vh] w-full mb-8   max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[720px]">
            <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                Sign up as patient
            </h4>
            <p className="mb-9 ml-1 text-base text-gray-600">
                Enter your Information to sign up!
            </p>

            <div className={"grid grid-cols-1 lg:grid-cols-2 gap-2"}>
                <FormItem
                    label={"Full Name"}
                    type={"error"}

                    variant={"auth"}
                    message={error?.user?.full_name}
                >
                    <input
                        value={form?.user?.full_name}
                        placeholder={"John"}
                        required={true}
                        type={"text"}
                        onChange={(e) => {
                            setForm({
                                ...form, user: {
                                    ...form.user,
                                    full_name: e.target.value

                                }
                            })
                        }}
                    />
                </FormItem>
                <FormItem
                    label={"Email"}
                    type={"error"}
                    variant={"auth"}
                    message={error?.user?.email}
                >
                    <input
                        value={form?.user?.email}
                        placeholder={"example@gmail.com"}
                        min={0}
                        required={true}
                        type={"email"}
                        onChange={(e) => {
                            setForm({
                                ...form, user: {
                                    ...form.user,
                                    email: e.target.value
                                }
                            })
                        }}
                    />
                </FormItem>

                <FormItem
                    label={"Gender"}
                    type={"error"}
                    variant={"auth"}
                    message={error?.user?.gender}
                >
                    <select
                        value={form?.user?.gender}
                        required={true}
                        className={"dark:bg-darkTheme-800 dark:text-white cursor-pointer"}
                        onChange={(e) => {
                            setForm({
                                ...form, user: {
                                    ...form.user,
                                    gender: e.target.value
                                }
                            })
                        }}
                    >
                        <option className={"text-base"} value={"MALE"}>Male</option>
                        <option className={"text-base"} value={"FEMALE"}>Female</option>
                        <option className={"text-base"} value={"OTHER"}>Other</option>
                    </select>
                </FormItem>
                <FormItem
                    label={"Date of Birth"}
                    type={"error"}
                    variant={"auth"}
                    message={error?.user?.date_of_birth}
                >
                    <input
                        value={form?.user?.date_of_birth}
                        required={true}
                        type={"date"}
                        onChange={(e) => {
                            setForm({
                                ...form, user: {
                                    ...form.user,
                                    date_of_birth: e.target.value
                                }
                            })
                        }}
                    />
                </FormItem>


                <FormItem
                    label={"CNIC/SSN"}
                    type={"error"}
                    variant={"auth"}
                    message={error?.user?.cnic}
                >
                    <input
                        value={form?.user?.cnic}
                        placeholder={"cnic/ssn number"}
                        required={true}
                        type={"text"}

                        pattern={'^\\d{11}$'}
                        title={"CNIC should be 11 digits"}
                        onChange={(e) => {
                            setForm({
                                ...form, user: {
                                    ...form.user,
                                    cnic: e.target.value
                                }
                            })
                        }}
                    />
                </FormItem>
                <FormItem
                    label={"Marital Status"}
                    type={"error"}
                    variant={"auth"}
                    message={error?.user?.martial_status}
                >
                    <select
                        value={form?.user?.martial_status}
                        required={true}
                        className={"dark:bg-darkTheme-800 dark:text-white cursor-pointer"}
                        onChange={(e) => {
                            setForm({
                                ...form, user: {
                                    ...form.user,
                                    martial_status: e.target.value
                                }
                            })
                        }}
                    >
                        <option className={"text-base"} value={"SINGLE"}>Single</option>
                        <option className={"text-base"} value={"MARRIED"}>Married</option>
                        <option className={"text-base"} value={"OTHER"}>Other</option>
                    </select>
                </FormItem>
                <FormItem
                    label={"Chronic Disease"}
                    type={"error"}
                    variant={"auth"}
                    message={error?.have_chronic_disease}
                >
                    <select
                        value={form?.have_chronic_disease ? "Yes" : "No"}
                        required={true}
                        className={"dark:bg-darkTheme-800 dark:text-white cursor-pointer"}
                        onChange={(e) => {
                            setForm({...form, have_chronic_disease: e.target.value === "Yes"})
                        }}
                    >
                        <option className={"text-base"} value={"Yes"}>Yes</option>
                        <option className={"text-base"} value={"No"}>No</option>
                    </select>
                </FormItem>
                <FormItem
                    label={"IFY Disease"}
                    type={"error"}
                    variant={"auth"}
                    message={error?.ify_disease}
                >
                    <input
                        value={form?.ify_disease}
                        placeholder={"if any"}
                        type={"text"}
                        onChange={(e) => {
                            setForm({...form, ify_disease: e.target.value})
                        }}
                    />
                </FormItem>
                <FormItem
                    label={"Address"}
                    type={"error"}
                    variant={"auth"}
                    message={error?.user?.address}
                >
                    <input
                        value={form?.user?.address}
                        placeholder={"address"}
                        type={"text"}
                        onChange={(e) => {
                            setForm({...form, user: {...form.user, address: e.target.value}})
                        }}
                    />
                </FormItem>
                <FormItem
                    label={"Contact"}
                    type={"error"}
                    variant={"auth"}
                    message={error?.contact}
                >
                    <input
                        value={form?.contact}
                        placeholder={"contact"}
                        required={true}
                        type={"text"}
                        pattern={"^\+234\d{10}$"}
                        title={"Contact should be in +234xxxxxxxxxx format"}
                        onChange={(e) => {
                            setForm({...form, contact: e.target.value})
                        }}
                    />
                </FormItem>
                <FormItem
                    label={"Emergency Contact"}
                    type={"error"}
                    className={""}
                    variant={"auth"}
                    message={error?.emergency_contact}
                >
                    <input
                        value={form?.emergency_contact}
                        placeholder={"Emergency contact"}
                        required={true}
                        type={"text"}
                        pattern={"^\+234\d{10}$"}
                        title={"Contact should be in +234xxxxxxxxxx format"}
                        onChange={(e) => {
                            setForm({...form, emergency_contact: e.target.value})
                        }}
                    />
                </FormItem>
                <FormItem
                    label={"Password"}
                    type={"error"}
                    className={""}
                    variant={"auth"}
                    message={error?.user?.password}
                >
                    <input
                        value={form?.user?.password}
                        placeholder={"password"}

                        type={"password"}
                        onChange={(e) => {
                            setForm({
                                ...form, user: {
                                    ...form.user,
                                    password: e.target.value
                                }
                            })
                        }}
                    />
                </FormItem>

            </div>
            <FormButton
                loading={loading}
                type={"submit"} className={" mt-8 w-[500px] m-auto self-center "}>
                Submit
            </FormButton>
            <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Already have an account?
          </span>
                <Link
                    to={"/auth/login"}

                    className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                >
                    Login
                </Link>
            </div>

            <div className={"h-[100px]"}/>
        </form>

    )
}